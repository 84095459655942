@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Familjen Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-sizing: border-box; */
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: "white";
}

a:active,
a:focus {
  color: "white";
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 1.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* iframe {
  display: none;
} */

/* App.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  transform: translateY(100%);
}

.black-overlay {
  background-color: black;
}

.white-overlay {
  background-color: white;
}

.marquee-container {
  width: 100%;
  /* margin: 100px auto; */
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
.content-wrapper {
  padding-inline: 8px;
  display: flex;
  column-gap: 16px;
  align-items: center;
  animation: scroll 5s linear infinite;
}

.content-wrapper > p {
  white-space: nowrap;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
